import { useState } from 'react'
import { useSession } from 'next-auth/react'
import createSupabaseClient from '@/lib/supabase/createSupabaseClient'
import { toast } from '@/utils/helpers/toast'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

const WEB3ME_URL = process.env.NEXT_PUBLIC_WEB3ME_URL || 'https://web3me.xyz'

const AddRecommendationModel = ({ open, onClose, setUrl, url, profiledata }) => {
   const [copied, setCopied] = useState(false)

   //console.log("In create url data",profiledata)
   const handleCreateUrl = async () => {
      try {
         const supabase = createSupabaseClient()

         // Create a new entry
         const { data: newData, error } = await supabase
            .from('pp_recommendation_links')
            .insert({
               creator_address: profiledata.owner ? profiledata.owner : '',
               username: profiledata.username ? profiledata.username : '',

            })
            .select('id')
            .single()

         if (error) {
            throw new Error(error.message)
         }

         const newUrl = `${WEB3ME_URL}/recommendation/${newData.id}/${profiledata.username}`
         setUrl(newUrl)
         //toast.success('Recommendation URL created')
      } catch (error) {
         toast.error(error.message)
      }
   }

   const handleCopy = () => {
      navigator.clipboard.writeText(url)
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
   }

   return (
      <Transition.Root show={open} as={Fragment}>
         <Dialog as="div" className="relative z-30" onClose={onClose}>
            <Transition.Child
               as={Fragment}
               enter="ease-out duration-300"
               enterFrom="opacity-0"
               enterTo="opacity-100"
               leave="ease-in duration-200"
               leaveFrom="opacity-100"
               leaveTo="opacity-0"
            >
               <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-30 overflow-y-auto">
               <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                  <Transition.Child
                     as={Fragment}
                     enter="ease-out duration-300"
                     enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                     enterTo="opacity-100 translate-y-0 sm:scale-100"
                     leave="ease-in duration-200"
                     leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                     leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                     <Dialog.Panel className="relative w-full transform flex flex-col gap-2 bg-neutral-800 border border-white/20 backdrop-blur-lg px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                        <div className="flex justify-between items-center flex gap-10">
                           
                           <h2 className="text-2xl text-white font-bold">Add recommendation</h2>
                           <button
                              type="button"
                              className="bg-white hover:bg-transparent hover:text-white hover:border border-white text-black font-bold py-2 px-4 rounded-full"
                              onClick={onClose}
                           >
                              Close
                           </button>
                        </div>
                        <p className="text-sm text-gray-200 mb-3">
                           Ask your clients or employers to verify that you&apos;ve worked together!
                           Recommendations are signed messages that are verified on the blockchain.
                           Show to your audience what other people you&apos;ve worked with have to say
                           about you.
                        </p>

                        <div className="flex flex-col w-full pt-4">
                           {url ? (
                              <div className="w-full">
                                 <p className="text-sm text-gray-200 mb-3">Share this URL to get recommendations:</p>
                                 <div className="flex items-center space-x-3 mb-3 p-1">
                                    <input
                                       type="text"
                                       value={url}
                                       readOnly
                                       className="w-full bg-neutral-700 text-white p-2 rounded"
                                    />
                                    <button
                                       className="bg-white text-black font-bold py-2 px-4 rounded-full"
                                       onClick={handleCopy}
                                    >
                                       {copied ? 'Copied!' : 'Copy'}
                                    </button>
                                 </div>
                              </div>
                           ) : (
                              <div className="p-5 w-full">
                                 <button
                                    className="bg-white hover:bg-transparent hover:text-white hover:border border-white text-black font-bold py-2 px-4 rounded-full w-full"
                                    onClick={handleCreateUrl}
                                 >
                                    Create a URL
                                 </button>
                              </div>
                           )}
                        </div>
                     </Dialog.Panel>
                  </Transition.Child>
               </div>
            </div>
         </Dialog>
      </Transition.Root>
   )
}

export default AddRecommendationModel