import { useRouter } from 'next/router'
import ConnectedWallet from '@/components/ConnectedWallet'
import { TITLE } from '@/config'
import useWallets from '@/hooks/useWallets'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { useSession, signIn, getCsrfToken } from 'next-auth/react'
import Head from 'next/head'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import useSelectedWallet from '@/hooks/useSelectedWallet'
import Link from 'next/link'
import Image from 'next/image'
import MELogo from '../../public/logos/magicEden.png'
import { useConnect } from 'wagmi'
import { magicEdenWallet } from '../../utils/helpers/magicEdenConnector'
import { toast } from '@/utils/helpers/toast'
import { SiweMessage } from 'siwe'
import { useAccount, useSignMessage } from 'wagmi'
import AddRecommendationButton from '@/components/AddRecommendationButton'
import { FaFileAlt } from 'react-icons/fa';
import { IoNewspaperOutline } from "react-icons/io5";


// Removed the problematic import
//import { getUser } from './auth/[...nextauth]';

// --------------------------------------------------------------------------------

const Layout = ({ headTitle, children, maxMd, showWallet = true, noPadding, cvs, external }) => {
   const { data: session, update: updateSession } = useSession()
   const { wallets, addWallet, resetWallets } = useWallets()
   const connectedWallets = useMemo(() => Object.values(wallets), [wallets])
   const [, updateSelectedWallet] = useSelectedWallet()
   const { connectAsync } = useConnect()
   const { address, isConnected } = useAccount()
   const { signMessageAsync } = useSignMessage()
   const router = useRouter()


   useEffect(() => {
      if (!session?.address) {
         resetWallets()
         updateSelectedWallet(null)
      } else if (session?.address && !Object.keys(wallets)?.includes(session?.address)) {
         addWallet(session)
      }
   }, [session])

   const handleMELogoClick = async () => {
      // Check if Magic Eden wallet is available
      if (typeof window !== 'undefined' && !window.magicEden) {
         window.open('https://wallet.magiceden.io/', '_blank', 'noopener,noreferrer');
         return;
      }

      try {
         const wallet = magicEdenWallet();
         const meAddress = await wallet.connect();
         const csrfToken = await getCsrfToken()

         // Create SIWE message
         const message = new SiweMessage({
            domain: window.location.host,
            address: meAddress,
            statement: 'Sign in with Ethereum to the app.',
            uri: window.location.origin,
            version: '1',
            chainId: 1,
            nonce: csrfToken
         })

         // Sign the message
         const signature = await wallet.signMessage(message.prepareMessage())

         // Verify the signature
         const verifyResult = await signIn('credentials', {
            message: JSON.stringify(message),
            signature,
            redirect: false,
         })

         if (verifyResult?.error) {
            throw new Error(verifyResult.error)
         }

         await updateSession({ address: meAddress })
         updateSelectedWallet(meAddress);
         addWallet({ address: meAddress, type: 'Magic Eden' });

         toast.success('Connected to Magic Eden Wallet');
      } catch (error) {
         console.error('Error connecting to Magic Eden Wallet:', error);
         toast.error(`Error: ${error.message}`);
      }
   };

   const backgroundClass = external ? 'bg-[url("/images/retro_bg.png")]' : 'bg-black'
   const shouldHideLink = router.pathname.startsWith('/create');

   return (
      <>
         <Head>
            <title>{`${headTitle ? `${headTitle} |` : ''} ${TITLE}`}</title>
         </Head>

         <div
            className={`application-wrapper flex z-20 flex-col w-screen h-full min-h-screen ${backgroundClass} bg-no-repeat bg-cover bg-top`}
         >
            <div className={`flex z-20`}>
               <div
                  className={twMerge(
                     'flex flex-col w-screen justify-center max-w-[1607px] mx-auto -pt-3',
                     maxMd && 'max-w-[600px]',
                     noPadding ? '' : 'px-3',
                     'no-bg-on-small' // Add this class
                  )}
                  style={{
                     backgroundImage: `url('/images/frame_logo.png')`,
                     backgroundRepeat: 'no-repeat',
                     backgroundPosition: 'top',
                  }}
               >
                  <nav
                     className={twMerge(
                        'home-navigation flex flex-row items-center pt-10 sm:pb-4 sm:pt-10 mt-[4.5rem] sm:mt-10',
                        router.pathname === '/create/personal' ? 'justify-between' : 'justify-end',
                        noPadding ? 'px-2' : ''
                     )}
                  >

                     {showWallet && (
                        <>

                           <div className={'flex flex-row shrink items-center'}>
                              {connectedWallets.length ? (
                                 <ConnectedWallet cvs={cvs} />
                              ) : (
                                 <div className="wallet-connect-container flex flex-col gap-3 items-end sm:flex-row sm:gap-5 sm:items-center" >
                                    <div className=' text-white w-full font-bold flex flex-row items-center' style={{ position: 'relative' }}>
                                       <button
                                          className='me-wallet-model gap-2 text-white font-bold flex flex-row items-center hidden sm:block' // Hide on small screens
                                          onClick={handleMELogoClick}
                                          style={{
                                             background: '#dfebe4',
                                             border: 'none',
                                             cursor: 'pointer',
                                             color: '#e6e6e6eb',
                                             padding: '9px 8px',
                                             justifyContent: 'center',
                                             position: 'relative',
                                             boxShadow: 'rgb(239, 86, 255) 0px 0px 4px',
                                             borderRadius: '5px',
                                             backgroundImage: 'url("/wallet_rewards.gif")',
                                             backgroundSize: 'cover',
                                             width: '100%',
                                             height: '41px',
                                             backgroundPosition: 'center'
                                          }}
                                       > <span style={{ 'background-color': '#00000073' }}>Connect</span>

                                       </button>
                                    </div>
                                    <ConnectButton />

                                 </div>
                              )}
                              {!shouldHideLink && (
                                 <Link href="https://docs.theforsaken.xyz" className="ml-3">
                                    <IoNewspaperOutline size={35} color="rgb(21 214 206)" />
                                 </Link>
                              )}
                           </div>
                        </>
                     )}
                  </nav>

                  {children}
               </div>
            </div>
         </div>


         {external ? null : (
            <div
               style={{
                  backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0)), url('/images/bg_grid.jpg')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'top',
                  backgroundSize: 'cover',
                  minHeight: '180px',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  paddingBottom: '15px',
                  zIndex: '20',
               }}
               className={'px-5'}
            >
               <div className="flex flex-col items-center py-15 sm:flex-row-reverse sm:justify-between">
                  <div className="flex gap-x-6">
                     {/* <p className="text-white">
                        <a href="mailto:sd" className={'hover:underline'}>
                           contact@forsaken.com
                        </a>
                     </p> */}
                     <Link
                        href="https://twitter.com/Web3meID"
                        className="group z-20"
                        aria-label="Web3me on X"
                     >
                        <svg
                           aria-hidden="true"
                           className="h-6 w-6 fill-gray-300 group-hover:fill-white"
                        >
                           <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0 0 22 5.92a8.19 8.19 0 0 1-2.357.646 4.118 4.118 0 0 0 1.804-2.27 8.224 8.224 0 0 1-2.605.996 4.107 4.107 0 0 0-6.993 3.743 11.65 11.65 0 0 1-8.457-4.287 4.106 4.106 0 0 0 1.27 5.477A4.073 4.073 0 0 1 2.8 9.713v.052a4.105 4.105 0 0 0 3.292 4.022 4.093 4.093 0 0 1-1.853.07 4.108 4.108 0 0 0 3.834 2.85A8.233 8.233 0 0 1 2 18.407a11.615 11.615 0 0 0 6.29 1.84" />
                        </svg>
                     </Link>
                     <Link
                        href="https://discord.com/invite/forsakennft"
                        className="group z-20"
                        aria-label="Forsaken on Discord"
                     >
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           width="1em"
                           height="1em"
                           viewBox="0 0 24 24"
                           className="h-6 w-6 text-gray-300 group-hover:text-white"
                        >
                           <path
                              fill="currentColor"
                              d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02zM8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12zm6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12z"
                           />
                        </svg>
                     </Link>
                  </div>
                  <p className="mt-6 text-sm text-gray-200 sm:mt-0">
                     Copyright &copy; {new Date().getFullYear()} The Forsaken. All rights reserved.
                  </p>
               </div>
            </div>
         )}
      </>
   )
}

// --------------------------------------------------------------------------------

Layout.defaultProps = {
   title: '',
   gifBg: false,
}

Layout.propTypes = {
   title: PropTypes.string,
   gifBg: PropTypes.bool,
   children: PropTypes.node.isRequired,
}

// --------------------------------------------------------------------------------

export default Layout