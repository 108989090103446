'use client'

import { useState } from 'react'
import { useSession } from 'next-auth/react'
import createSupabaseClient from '@/lib/supabase/createSupabaseClient'
import { toast } from '@/utils/helpers/toast'
import AddRecommendationModel from '@/components/pages/CreateCv/Experience/WorkExperience/AddRecommendationModel'

const AddRecommendationButton = ( {profiledata}) => {
   const [isModalOpen, setIsModalOpen] = useState(false)
   const [url, setUrl] = useState('')

//console.log("Recommendation data",profiledata)
const receiver_address= profiledata.owner;
//console.log("Recommendation data ID",receiver_address)


   const handleAddRecommendationClick = async () => {
      //console.log('Add Recommendation button clicked')
      try {
         const supabase = createSupabaseClient()
         const username = profiledata.username.toString()

         // Check if an entry already exists
         const { data: existingEntries, error: fetchError } = await supabase
            .from('pp_recommendation_links')
            .select('id, experience_id')
            .eq('creator_address', receiver_address)
            .eq('username', username) // Add username as a parameter

        

         if (fetchError && fetchError.code !== 'PGRST116') {
            throw new Error(fetchError.message)
         }

         if (existingEntries && existingEntries.length > 0) {
            const hasIncompleteExperience = existingEntries.some(entry => !entry.experience_id || entry.experience_id.trim() === '')
   
            if (hasIncompleteExperience) {
               // If any record has experience_id as null, empty, or blank
               const incompleteEntry = existingEntries.find(entry => !entry.experience_id || entry.experience_id.trim() === '')
               const existingUrl = `${process.env.NEXT_PUBLIC_WEB3ME_URL}/recommendation/${incompleteEntry.id}/${profiledata.username}`
               setUrl(existingUrl)
            } 
            setIsModalOpen(true)
         } else {
            // If no entry exists, open the modal to create a new one
            setIsModalOpen(true)
         }
      } catch (error) {
         toast.error(error.message)
      }
   }

   return (
      <>
         <button
            className='bg-white w-full hover:bg-transparent hover:text-white hover:border border-white text-black font-bold py-2 px-4 rounded-full'
            onClick={handleAddRecommendationClick}
         >
            Generate Recommendation Link
         </button>
         <AddRecommendationModel  open={isModalOpen} onClose={() => setIsModalOpen(false)} setUrl={setUrl} url={url} profiledata={profiledata}/>
      </>
   )
}

export default AddRecommendationButton